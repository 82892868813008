import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import professional_image from "../images/professional_image.jpg";
import logo from "../images/logo.png";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

const sections = [
	{
		title: "",
		content: {
			type: "card",
			display: {
				flexDirection: "row",
				md: 8,
			},
			value: {
				src: logo,
				alt: "Narrative Rewrite Logo in Banner",
				title: "What is Narrative Therapy?",
				content: {
					type: "string",
					value:
						"Narrative therapy seeks to be a respectful, non-blaming approach to counselling and community work, which centres people as the experts in their own lives. It views problems as separate from people and assumes people have many skills, competencies, beliefs, values, commitments and abilities that will assist them to reduce the influence of problems in their lives. - Dulwich Centre",
				},
			},
		},
	},
	{
		title: "",
		content: {
			type: "card",
			reverse: true,
			display: {
				flexDirection: "row-reverse",
				md: 4,
			},
			value: {
				src: professional_image,
				alt: "Professional Image of Counsellor Shyamal on the Banner",
				title: "About Shyamal",
				content: {
					type: "string",
					value:
						"Shyamal (pronounced: Sha – mull) Swamy is a registered counsellor, who has been practicing as a telephone counsellor for two years. She has experience counselling people in various age groups, jobs, and ethnicities. She has dealt with a wide range of problem stories such as Anxiety, Depression,	Stress, Relationship concerns, Communication, Conflict resolution, and historical Trauma and Abuse. She enjoys using narrative therapy tools to help her clients find alternate stories for themselves in order to free them from the problem stories. Shyamal works with a client centered philosophy that privileges the client’s values and needs in every session.",
				},
			},
		},
	},
	{
		title: "Approach to Counselling",
		content: {
			type: "list",
			key: "approach",
			value: [
				{
					type: "string",
					key: "approach1",
					value:
						"Having grown up knowing a lot of people for whom counselling and therapy has not been a safe space, I am passionate about creating that safe space for my clients. I do this by fully acknowledging that I do not play the role of the “expert” who presumes to know what’s best for my client. I privilege my client’s values and beliefs and assist them in finding ways to stand up to the problem that’s affecting them. In our sessions, we can explore the problem, it’s story and the part it has played in your life, while also exploring stories you would prefer to be part of your life.",
				},
				{
					type: "string",
					key: "approach2",
					value:
						"Another way I strive to create a safe space in counselling is by allowing myself to feel safe and be authentic. This means that you will see me fumble words, use gestures to punctuate my sentences, and even be awkward and very human. My hope is that in allowing myself to be safe and authentic, I also extend that acceptance to my clients.",
				},
				{
					type: "string",
					key: "approach3",
					value:
						"I believe that part of being a counsellor is also taking care of yourself so you can be fully present and give your client your time and energy. In my time outside work, I engage in a lot of activities that help me rejuvenate and come back to the session refilled. I enjoy oil painting, writing poetry, playing board games with my family, and spending time with my cats.",
				},
			],
		},
	},
	{
		title: "",
		content: {
			type: "grid",
			key: "details",
			value: [
				{
					title: "Qualifications & Registration",
					content: {
						type: "string",
						key: "qualifications",
						value:
							"Shyamal has a Master of Counselling degree from Monash University and is registered with Psychotherapy and Counselling Federation of Australia (PACFA). She attends Professional Development courses regularly to improve and expand her skills as a counsellor. She engages in several Narrative therapy courses to add to her knowledge in this area and hopes to be a researcher of narrative practice in the future. She also undergoes regular supervision to support her practice.",
					},
				},
				{
					title: "Individual Sessions",
					content: {
						type: "string",
						key: "fees",
						value: "Fee for 50 minutes session  - $90",
					},
				},
				{
					title: "Contact",
					content: {
						type: "list",
						value: [
							{
								type: "string",
								key: "emergency",
								value:
									"If this is an emergency please contact the following emergency services first.",
							},
							{
								type: "list",
								key: "help",
								value: [
									{
										type: "link",
										key: "help1",
										value: { text: "Triple-Zero (000)", href: "tel:000" },
									},
									{
										type: "link",
										key: "help2",
										value: { text: "Lifeline (13 11 14)", href: "tel:131114" },
									},
									{
										type: "link",
										key: "help3",
										value: {
											text: "Suicide Callback Service (1300 659 467)",
											href: "tel:1300659467",
										},
									},
								],
							},
							{
								type: "string",
								key: "practice",
								value:
									"This is currently a telehealth practice only and does not offer face to face counselling. If you are interested in having a quick chat with me and exploring if you want to book in a session, kindly drop me an email with your contact details and I will get in touch with you.",
							},
							{
								type: "link",
								key: "email",
								value: {
									href: "mailto:shyamal@narrativerewrites.com.au",
									text: "shyamal@narrativerewrites.com.au",
								},
							},
						],
					},
				},
			],
		},
	},
];

const createContent = ({ type, value, key, display }) =>
	type === "string" ? (
		<Typography sx={{ my: 2 }} component="p" key={key}>
			{value}
		</Typography>
	) : type === "link" ? (
		<Link sx={{ my: 2 }} href={value.href} key={key}>
			<Typography component="p" color="secondary">
				{value.text}
			</Typography>
		</Link>
	) : type === "list" ? (
		value.map(createContent)
	) : type === "card" ? (
		<Grid
			container
			key={key}
			textAlign={"center"}
			justifyContent="space-evenly"
			component={Card}
			sx={{
				display: "flex",
				flexDirection:
					window.screen.availWidth <= 500 ? "column" : display.flexDirection,
			}}
		>
			<Grid item xs={12} sm={12} md={display.md}>
				<CardMedia component="img" image={value.src} alt={value.alt} />
			</Grid>
			<Grid
				item
				component={CardContent}
				xs={12}
				sm={12}
				md={12 - display.md}
				sx={{
					display: { xs: "block", sm: "flex" },
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				{Section(value)}
			</Grid>
		</Grid>
	) : type === "grid" ? (
		<Grid
			container
			key={key}
			alignItems="stretch"
			textAlign={"center"}
			justifyContent="space-evenly"
		>
			{value.map((item, index) => (
				<Grid item component={Card} xs={12} sm={3.75} key={index}>
					<CardContent>{Section(item)}</CardContent>
				</Grid>
			))}
		</Grid>
	) : null;

const Section = ({ title, content }, index) => (
	<Box key={index} sx={{ my: 4 }}>
		<Typography component="h5" variant="h5" textAlign={"center"}>
			{title}
		</Typography>
		{createContent(content)}
	</Box>
);

const Home = () => (
	<Container sx={{ my: 15 }}>{sections.map(Section)}</Container>
);

export default Home;
