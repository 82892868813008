import { Box, Container, Link, Paper } from "@mui/material";
import Copyright from "./CopyrightComponent";

const Footer = () => (
	<Paper sx={{ boxShadow: "0px -3px 2px rgba(50, 50, 50, 0.25)", position: "fixed", bottom: 0, width: "100%" }}>
		<Box px={{ xs: 1, sm: 3 }} py={{ xs: 1, sm: 2 }}>
			<Container>
				<Box textAlign="center">
					<Link href="/" color="secondary">
						Home
					</Link>
				</Box>
				<Box textAlign="center" pt={{ xs: 1, sm: 2 }} pb={{ xs: 1, sm: 0 }}>
					<Copyright />
				</Box>
			</Container>
		</Box>
	</Paper>
);

export default Footer;
