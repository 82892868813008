import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

const Copyright = () => (
	<Typography variant="body2" color="text.secondary" align="center">
		<Link color="inherit" href="/">
			Narrative Rewrites
		</Link>
		{" © "}
		{new Date().getFullYear()}
		{"."}
	</Typography>
);

export default Copyright;
