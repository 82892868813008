import Home from "./pages/HomePage";
import { Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Header from "./components/Header";
import Footer from "./components/Footer";

const theme = createTheme({
	palette: {
		type: "light",
		primary: {
			main: "rgba(255,255,255,0)",
		},
		secondary: {
			main: "#880e4f",
		},
	},
});

function App() {
	return (
		<ThemeProvider theme={theme}>
			<Header />
			<Routes>
				<Route path="/" element={<Home />} />
			</Routes>
			<Footer />
		</ThemeProvider>
	);
}

export default App;
